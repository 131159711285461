import React from "react";
import { navigate } from "gatsby";

import { useLanguage } from "../components/LanguageSelect";

// const props = { children, location }
const IndexPage = () => {
  const language = useLanguage();

  if (typeof window !== `undefined`) {
    navigate(`/${language}/`);
  }

  return <></>;
};

export default IndexPage;
